.intro-container {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 40px;
    z-index: 1;
    min-height: 360px;
}

.intro-img-container{
  padding: auto;
  text-align: center;
  height: 330px;
}

.intro-section-header {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 24pt;
}

.intro-text-block {
  flex: 1;
  margin-right: 100px;
  margin-top: 1rem;
}

.intro-block-text {
  font-size: 20px;
  margin-top: 3rem;
  width: 90%;
}

.intro-img {
  display: block;
  text-align: center;
  max-width: 320px;
  height: 320px;
  border-radius: 5%;
  margin: auto; 
  margin-top: 3.5rem;
  border: #17ba17 solid 5px;
}

@media (max-width: 768px) {
  .intro-container {
    flex-direction: column;
  }
  .intro-text-block {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .intro-block-text {
    width: 100%;
  }
  .intro-section-header {
    font-size: 18pt;
  }
  .intro-img {
    margin-top: 1rem;
  }
}