/* Household CSS */

.household-section-header {
    text-align: left;
    font-size: 28pt;
    margin-bottom: -1rem;
}

.household-container {
    padding: 20px;
}

.household-section-subtitle {
    margin-bottom: 40px; 
    text-align: left;
    font-size: 20pt;
    color: gray;
}

.household-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Business CSS */

.business-section-header {
    text-align: left;
    font-size: 28pt;
    margin-bottom: -1rem;
}

.business-container {
    padding: 20px;
}

.business-section-subtitle {
    margin-bottom: 40px; 
    text-align: left;
    font-size: 20pt;
    color: gray;
}
  
.businesses-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* General Form CSS */
  
.form-group {
    display: flex;
    align-items: center;
}

.form-input {
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    flex: 1;
}

.form-agreement-link {
    margin-left: 0.25rem;
    text-decoration: none;
    color: blue;
    font-size: 1.2rem;
}

.form-submit {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #19BA17;
    color: white;
    cursor: pointer;
}

.form-submit:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-box {
    display: flex;
    border-radius: 0.3rem;
    border: solid red 1px;
    background-color: rgb(237, 197, 197);
    justify-content: space-around;
    padding: 1.5rem;
}

.success-box {
    display: flex;
    border-radius: 0.3rem;
    border: solid green 1px;
    background-color: rgb(202, 244, 220);
    justify-content: space-around;
    padding: 1.5rem;
}
  
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    text-align: center;
}

.checkbox-text {
    font-size: 20px;
}
  
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
