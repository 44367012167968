body {
  padding-top: 90px;
  overscroll-behavior: none;
}

.home-link {
  text-decoration: underline;
  color: #19BA17;
  font-size: 13pt;
  margin-top: 10px;
  padding-top: 15rem;
  margin-bottom: 100px;
  padding-bottom: 100px;
}
