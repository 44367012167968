.hamburger-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 40px;
    height: 24px;
  }
  
  .hamburger-button span {
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }