/* Confirmation Page */

.account-form-container {
    margin: auto;
    margin-top: 1rem;
    width: 400px;
    background-color: #f0f0f03f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
  }

  .contract-form-container {
    margin: auto;
    margin-top: 1rem;
    width: 90%;
    background-color: #f0f0f03f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
  }

  
  .input-container {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .confirming-input-field {
    width: 100%;
    max-width: 377px;
    display: block;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input-select-field {
    width: 100%;
    max-width: 400px;
    display: block;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .confirming-page-button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    background-color: #19BA17;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }

  .confirming-page-button:disabled {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    background-color: #8e928e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: default;
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    .text-block {
      width: 100%;
    }
    .account-form-container {
      width: 280px;
    }

    .contract-form-container {
      width: 400px;
    }
    .confirming-input-field {
      width: 92%;
    }
  }

/* Contract Page */
.contract-page-container {
    margin: 20px;
  }
  
  .contract-page-iframe {
    border: none;
    margin-top: 20px;
  }
  
  .contract-page-form {
    margin-top: 20px;
  }
  
  .contract-page-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contract-page-button {
    padding: 10px 20px;
    background-color: #19BA17;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .contract-page-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Scheduling page */
  .scheduling-page-container {
    margin: 20px;
  }

  .scheduling-page-button {
    padding: 10px 20px;
    background-color: #19BA17;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
    width: 100%;
  }
  
  .scheduling-page-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .rbc-selected-event {
    background-color: lightgreen !important;
  }
  
  .rbc-selected-event:hover {
    background-color: lightgreen !important;
  }
  
  .appointment-scheduled-box {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 50px auto;
    text-align: center;
  }
  
  .appointment-scheduled-box p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .appointment-scheduled-box button {
    background-color: #19BA17;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .appointment-scheduled-box button:hover {
    background-color: #168015;
  }

  /* Menu page */

  .custom-grid-container {
    margin: 20px;
  }
  
  .custom-panel {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .custom-panel-link {
    text-decoration: none;
    color: inherit;
  }
  
  .custom-panel-title {
    margin-top: 0;
  }
  
  .custom-panel-text {
    margin-bottom: 0;
  }

  .menu-page-button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    background-color: #19BA17;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .payout-method {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 0.4rem;
    border-radius: 8px;
    background-color: white;
  }

  /* Payout Page */
  
  .payout-page-container {
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0.2rem;
    width: 100%;
    margin: auto;
  }
  
  .payout-methods-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .payout-method-box {
    width: 150px;
    height: 150px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }
  
  .ach-box {
    background-color: #3174ad;
    color: white;
  }
  
  .paypal-box {
    background-color: grey;
    color: white;
    cursor: not-allowed;
  }
  
  .venmo-box {
    background-color: grey;
    color: white;
    cursor: not-allowed;
  }
  
  .payout-page-button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    background-color: #19BA17;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  
.stripe-provider {
  width: 100%;
}

.stripe-inner-provider {
  width: 100%;
}

/* Dark mode styles for react-big-calendar */
.dark-calendar {
  background-color: #374151 !important; /* gray-700 */
  color: #ffffff !important; /* white */
}

.dark-calendar .rbc-header {
  background-color: #4b5563 !important; /* gray-600 */
  color: #ffffff !important;
  border-color: #4b5563 !important; /* gray-600 - darker border */
}

.dark-calendar .rbc-time-header {
  background-color: #374151 !important;
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-time-content {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-time-slot {
  color: #ffffff !important;
}

.dark-calendar .rbc-time-gutter {
  background-color: #374151 !important;
  color: #ffffff !important;
}

.dark-calendar .rbc-off-range-bg {
  background-color: #1f2937 !important; /* gray-800 */
}

.dark-calendar .rbc-time-view {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-today {
  background-color: #1f2937 !important;
}

.dark-calendar .rbc-current-time-indicator {
  background-color: #3b82f6 !important; /* blue-500 */
}

.dark-calendar .rbc-timeslot-group {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-time-column {
  border-color: #4b5563 !important;
}

/* Navigation and view controls */
.dark-calendar .rbc-toolbar button {
  color: #ffffff !important;
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-toolbar button:hover {
  background-color: #4b5563 !important;
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-toolbar button.rbc-active {
  background-color: #4b5563 !important;
  color: #ffffff !important;
  border-color: #4b5563 !important;
}

/* Additional selectors for consistent styling */
.dark-calendar .rbc-label {
  color: #ffffff !important;
}

.dark-calendar .rbc-time-header-content {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-time-header-cell {
  color: #ffffff !important;
}

.dark-calendar .rbc-day-slot .rbc-time-slot {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-events-container {
  color: #ffffff !important;
}

.dark-calendar .rbc-time-slot span {
  color: #ffffff !important;
}

.dark-calendar .rbc-day-slot .rbc-event {
  border-color: #4b5563 !important;
}

.dark-calendar .rbc-toolbar {
  color: #ffffff !important;
}

.dark-calendar .rbc-btn-group {
  background-color: #374151 !important;
}

.dark-calendar .rbc-toolbar button:focus {
  background-color: #4b5563 !important;
  color: #ffffff !important;
}