/* Modal container styling */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content box */
.modal-content {
  background-color: #fefefe;
  padding: 40px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  z-index: 5;
  position: relative;
}

/* Modal title styling */
.modal-title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

/* Close button styling */
.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Options for business/household images */
.modal-options {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 30px;
}

.modal-img-left,
.modal-img-right {
  width: 80px;
  height: auto;
}

@media (max-width: 450px) {
  .modal-content {
    padding: 20px;
    width: 90%;
  }

  .modal-title {
    font-size: 1rem;
  }

  .modal-options {
    justify-content: space-between;
  }

  .modal-img-left,
  .modal-img-right {
    width: 70px;
  }
}

/* Login form styling */
.modal-login-form {
  margin-top: 20px;
  z-index: 6000;
}

/* Input field styling */
.modal-form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Password container for eye icon */
.password-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 15px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.password-toggle-icon:hover {
  color: #000;
}

/* Green login button */
.modal-button-login {
  width: 100%;
  padding: 10px;
  background-color: #19ba17; /* Green background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.modal-button-login:disabled {
  background-color: grey;
  cursor: not-allowed;
}

/* Modal footer styling */
.modal-footer {
  text-align: center;
  margin-top: 1rem;
}

.forgot-password-link {
  color: #007bff;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.login-modal-error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
  border: solid 1px red;
  border-radius: 5px;
  background-color: rgb(254, 228, 228);
  padding: 0.2rem;
}