.faq-container {
  margin: 40px;
  margin-top: 60px;
}

#faq {
  display: flex;
  flex-direction: column;
}

.faq-section-header {
  margin-bottom: 25px;
  text-align: left;
  font-size: 24pt;
}

.text-block {
  width: 60%;
}

.block-text {
  font-size: 20px;
}

.question {
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 530;
}

.question-wrapper {
  text-align: left;
  margin-bottom: 20px;
  font-size: 10pt;
  width: 90%;
  padding: 0.7rem;
  background-color: #f0f0f0;
  border-radius: 10px; 
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.question-wrapper:hover {
  background-color: #e9e9e9;
}

.arrow {
  padding-left: 1rem;
  display: inline-block;
  font-size: 15px;
}

.arrow::before {
  content: "\25B6";
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.arrow.open::before {
  transform: rotate(90deg);
}

.answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.answer.open {
  opacity: 1;
  max-height: 1000px;
}
