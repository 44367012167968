.howitworks-container {
    margin-top: 50px;
}

.howitworks-img-container {
  width: 580px;
  height: 900px;
  margin: auto;
  text-align: center;
}

.howitworks-section {
  margin: 20px;
}

#howItWorks {
  display: flex;
  flex-direction: column;
}

.howitworks-section-header {
  text-align: center;
  font-size: 24pt;
  margin-bottom: 2rem;
}

.text-block {
  width: 60%;
}

.howitworks-img {
  width: 350px;
  max-width: 100%;
  margin: auto;
}

@media (max-width: 768px) {
  .text-block {
    width: 100%;
  }
  .howitworks-img-container {
    width: 280px;
    height: 900px;
    margin: auto;
  }
  .howitworks-section {
    margin-bottom: -13rem;
  }
}
