.mission-container {
  position: relative;
  display: flex;
  background-color: #17ba17;
  padding: 40px;
  min-height: 330px;
}
  
.mission-img-container {
  margin-top: -2rem;
  padding: auto;
  text-align: center;
  height: 330px;
}

.mission-section-header {
  position: relative;
  display: inline;
  text-align: left; 
  font-size: 24pt;
  background-color: whitesmoke;
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
}

.mission-text-block {
    flex: 1;
    margin-right: 100px;
    margin-top: 3cqi;
  }
  
.mission-block-text {
    font-size: 20px;
    margin-top: 3rem;
    width: 90%;
}

.mission-img {
    display: block;
    text-align: center;
    max-width: 320px;
    height: 320px;
    border-radius: 5%;
    margin: auto; 
    margin-top: 3.7rem;
    border: #17ba17 solid 5px;
}

  @media (max-width: 768px) {
    .mission-container {
        flex-direction: column;
    }
    .mission-text-block {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .mission-block-text {
      width: 100%;
    }
    .mission-section-header {
      font-size: 18pt;
    }
    .mission-img {
      margin-top: 1rem;
    }
  }