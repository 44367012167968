header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  padding: 20px 0px;
  z-index: 3;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: white;
}

nav ul li a:hover {
  color: #ddd;
}

.dropbtn-2 {
  background-color: black;
  color: white;
  padding: 5px 22px;
  font-size: 16px;
  border: solid 0.1rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.dropbtn {
  background-color: #ffffff;
  color: black;
  padding: 5px 22px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}

.dropbtn:hover {
  background-color: #ddd;
}

.dropbtn-2:hover {
  background-color: #333333;
}

.logo-container {
    display: flex;
    align-items: center;
}
  
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhythm-logo {
  height: 50px;
  width: auto;
  margin-right: 20px;
  margin-left: 20px;
}