.referrals-section-image {
    display: block;
    width: 100%;
    height: 100%;
}

.referrals-section-header {
    margin-bottom: 60px;
    text-align: center;
    font-size: 28pt;
}

.referrals-container {
    margin: 20px;
}