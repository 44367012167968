.side-panel {
  position: fixed;
  top: 0;
  left: -300px;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
}

.side-panel.open {
  left: 0;
}

.side-panel ul {
  list-style: none;
  padding: 0;
  margin: 50px 0;
}

.side-panel ul li {
  margin-bottom: 20px;
  text-align: center;
}

.side-panel ul li a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-clickable {
  cursor: pointer;
}