.background-container {
  position: relative;
  z-index: 2;
  width: 100%;
}

.green-background {
  height: 750px;
  width: 100%;
  background-color: #17ba17;
  z-index: 0;
}

.circles-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.circle {
  position: absolute;
  top: 50%;
  right: 0%;
  border-radius: 100%;
  border: 25px solid #1daf1dab;
  transform: translate(50%, -50%);
  z-index: 1;
}

.home-page-content {
  position: absolute;
  top: 17%;
  left: 0%;
  margin-left: 5%;
  text-align: left;
  color: black;
  z-index: 2;
}

.sign-up-button {
  width: 8rem;
  background-color: #fff;
  color: black;
  border: white solid 0.1rem;
  border-radius: 0.3rem;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 2;
}

.sign-up-button:hover {
  background-color: rgb(243, 243, 243);
}

.log-in-button {
  width: 8rem;
  background-color: #19BA17;
  color: black;
  border-radius: 0.3rem;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 2;
  margin: 0.5rem;
  margin-left: 2.5rem;
  border: white solid 0.14rem;
}

.log-in-button:hover {
  background-color: #18b416;
}

.home-page-title {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 2px;
}

.home-page-subtitle {
  font-size: 20px;
  font-weight: 530;
  text-align: left;
  letter-spacing: 2px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
  
@media (max-width: 468px) {
  .home-page-content {
    top: 8%;
  }
  .scroll-down-arrow {
    top: 67%;
  }
  .log-in-button {
    margin-left: 1.5rem;
  }
}

.fade-in-out-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out-container.hidden {
  opacity: 0;
}

.fade-in-out-container.visible {
  opacity: 0.5;
}